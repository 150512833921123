.backForm {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 10px;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-signin {
  font-family: 'Oswald', sans-serif;
  background-color: #1D2129;
  color: #dddddd;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  padding: 15px;
  z-index: 10;
  text-align: center;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .form-signin {
    margin-top: 20px; /* Add margin on the top for mobile devices */
  }
}
.form-signup {
  font-family: 'Oswald', sans-serif;
  background-color: #1D2129;
  color: #dddddd;
  border-radius: 15px;
  width: 100%;
  max-width: 800px;
  padding: 15px;
  z-index: 10;
  text-align: center;
}

  .form-signin .checkbox {
    font-weight: 400;
    font-size: 12px;
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .form-signin a{
    color: #0979be;
  
  }
  .form-signin a:hover{
    color: #ffffff;
  
  }
  
  .pasInscrit{
    display: flex;
    margin-top: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .pasInscrit h6{
    margin-top: 8px;
    margin-left: 35px;
  }
  .btnBlue{
    background-color: #0979be;
    color: #a7e4e2;
    border: solid 2px #0979be;
  }
  .btnMenuLeft{
    margin-top: 8px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .btnMenuLeft:hover{
  
  color:#ffffff;
  
  }
  #btnSubscribe{
  
  }
  .btnBlue:hover{
    color:#ffffff;
    border: solid 2px #ffffff;
  }
  .logoSignin{
    width: 180px;
  }
  .logoBox{
    clear: both;
  }
  .btnClose{
    clear: both;
    display:block;
    font-family: 'Shippori Antique', sans-serif;
    float: right;
    cursor: pointer;
    text-transform:uppercase;
    font-size: 16px;
    
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    color:#dddddd;
  }
  .btnClose:hover{
  
    float: right;
    text-transform:uppercase;
    font-size: 16px;
    color:#dca953;
  
  }
  #btnClose{
    clear: both;
    display:block;
    font-family: 'Shippori Antique', sans-serif;
    float: right;
    cursor: pointer;
    text-transform:uppercase;
    font-size: 16px;
    border:2px solid #a7e4e2;
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    color:#0979be;
  }
  #btnClose:hover{
  
    float: right;
    text-transform:uppercase;
    font-size: 16px;
    border:2px solid #0979be;
  
  }
  
  .sousTitre{
    font-family: 'Oswald', sans-serif;
    margin-top: 30px;
    padding-bottom: 20px;
    font-weight: lighter;
  }
  .ouClass{
    padding-top: 20px;
    padding-bottom: 0px;
  }
  
  
  .difLoggin{
  height:50px;
  background-color: #0979be;
  color:#ffffff;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  }
  .difLoggin:hover{
    color:#a7e4e2;
  }
  
  .difLoggin span{
    margin-left: 10px;
    line-height: 50px;
  }
  .difLoggin img{
    margin-top: -5px;
    width: 25px;
    height: 25px;
  }
  
  .btnInscription{
    padding: 2px;
    margin-left: 10px;
    width: 100px;
    border: solid 2px #0979be;
    border-radius: 5px;
    cursor: pointer;
  }
  .btnInscription:hover{
    color:#ffffff;
  background-color: #0979be;
    border: solid 2px #ffffff;
  }
  