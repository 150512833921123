.account {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 80px;
}


.account .subTitle {
    width: 100%;
    background-color: #232f3e;
    border-radius: 5px;
    color: #999;
    display: flex;

    justify-content: space-between; /* Add this line */
    margin-top: 20px;
    padding: 10px;

}

.account .btn-Edit {
    cursor: pointer;
    background-image: url("../imgs/interface/editOn.png");
    background-repeat: none;
    background-size: contain;
    width:25px;
    height:25px;
    padding: 5px;
    
}

.account .btn-Save {
    cursor: pointer;
    background-image: url("../imgs/interface/save.png");
    background-repeat: none;
    background-size: contain;
    width:25px;
    height:25px;
    padding: 5px;
    
}

.account .btn-Add {
    cursor: pointer;
    background-image: url("../imgs/interface/add.png");
    background-repeat: none;
    background-size: contain;
    width:25px;
    height:25px;
    padding: 5px;
    
}

.account .imgUser img{
    border-radius: 10px;
    width: 100%;
}

.account .infos{
    text-align: left;
    padding-left: 10px;
}

.account .name{
    font-size: 22px;
    color:#999;
}

.account .btn-imgIcon{
    background-color: #232f3e;
    border-radius: 5px;
    cursor: pointer;
    width: 30px;
    margin-right: 10px;
}

.account .btn-imgIcon img{
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 5px;
}


.account .addressCard{
    margin-top: 20px;
    text-align: left;
    padding: 10px;
    border-radius: 10px;
    width: 250px;

}