.dock {
    display: flex;
    
    background-color: rgba(50, 50, 60, 0.9);
    
    min-height: 60px;
    position: fixed;
    bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    min-width: 60%;
    left: 20%;
    padding: 5px;
    z-index: 500;
}

.separator {
    display: block;
    width: 2px;
    height: 30px;
    background-color: #465363;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.dock-btn {
    position: relative;
    width: 50px;
    height: 50px;
}
.dock-btn .btn-title{
    position: absolute;
    background-color: #465363;
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
    bottom: 64px;
    font-size: 12px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.dock-btn .imgIcon{
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 5px;
    
}
.dock-btn .imgIcon:hover{
    background-color: rgba(150, 150, 150, 0.3);
}


.dock-btn img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
}

.white{
    color:#f3f3f3;
}
.white:hover{
    color:#f3f3f3;
}


/* New CSS to move the last separator and everything after it to the right */
.justify-content-end {
    display: flex;
    margin-left: auto;
}

.userMenu{
    position: fixed;
    display: block;
    background-color: #232f3e;
    bottom: 70px;
    border-radius: 10px;
    left: 20%;
    z-index: 500;
}

.userMenu .title{
    background-color: #465363;
    height: 50px;
    width: 100%;
    line-height: 50px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 150px;
    border-radius: 10px 10px 0px 0px;
}


.userMenu .menu-btn img{
    margin-left: auto;
    width:20px;
    height: 20px;
    margin-top: 8px;
    margin-right:10px ;
}

.userMenu .menu-btn {
    display: flex;
    height:36px;
    cursor: pointer;
}
.userMenu .menu-btn:hover {
    background-color: #2F3B4C;
}

.userMenu .menu-btn .texte {
    font-size: 14px;
    line-height: 36px;
    padding-left: 10px;
}
.userMenu .separatorH{
    display: block;
    min-width: 80%;
    min-height: 3px;
    background-color: #465363;

}