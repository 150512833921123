

.subscribe {
    background-color: #232f3e;
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    min-width: 300px;
    max-width: 400px;
    
}

@media (max-width: 768px) {
    .subscribe {
      margin-top: 20px;
    }
    
}  