@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Oswald:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  color:#f3f3f3;
  font-family: 'Oswald', sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #465363;
  background: linear-gradient(to right, #232f3e, #465363, #232f3e);
  max-width: 100vw;
  max-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.card-home {
  background-color: #232f3e;
  border-radius: 20px;
  color: #999999;
  cursor: pointer;
  width: 90%;
}

.card-home:hover {
  box-shadow: 2px 2px 10px 10px #343434;
}

.card-image {
  padding: 30px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.form-floating{
  color:#343434;
}

.menu-top {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  padding: 5px;
  display: flex;

}

.menu-top .menu-btn {
  display: block;
  min-width: 30px;
  min-height: 30px;
  margin-left: 5px;
  cursor: pointer;
}

.btn-notifications {
  background-image: url("./imgs/interface/notification.png");
  background-position: center center;
  background-size: 25px 25px;
  background-repeat: no-repeat;
}

.btn-notifications:hover {
  background-image: url("./imgs/interface/notification-over.png");

}

.btn-user {
  background-image: url("./imgs/interface/user.png");
  background-position: center center;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  margin-right: 5px;
}

.btn-user:hover {
  background-image: url("./imgs/interface/user-over.png");

}

.btn-launchpad {
  background-image: url("./imgs/interface/launchpad.png");
  background-position: center center;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  margin-right: 2px;
}

.btn-launchpad:hover {
  background-image: url("./imgs/interface/launchpad-over.png");

}

.btn-login {
  background-color: #dca953;
  color: #f1f1f1;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.btn-login:hover {
  color: #333333;
}


.btn-subscribe {
  color: #dca953;
  line-height: 34px;
  cursor: pointer;
}

.btn-subscribe:hover {
  color: #999999;
}

.notVerified{
  max-width: 80%;
}
/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  /* Adjust this value to make it thinner */
  height: 5px;
  /* Adjust this value to make it shorter */
}

/* Set the background color of the scrollbar track */
::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, #f1f1f1, #e6e6e6);
}

/* Set the color and border radius of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 2px;
}

/* Add a hover effect to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

.noDecoration{
  text-decoration: none;
}


.my-btn-primary {
  background-color: #dca953;
  color: #f1f1f1;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.my-btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #333333;
  background-color: #dca953;
}

.logo {
  width: 80%;
  max-width: 1000px;
}

/* Add this CSS to your component's stylesheet or in your global styles */


.intro {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.intro .carousel {
  width: 80%;
  height: 80%;
}

.intro .carousel .carousel-inner {
  height: 100%;
}

.intro .carousel .carousel-item {
  width: 80%;
  margin-left: 10%;
  height: 100%;

}

.intro .carousel .carousel-item .container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slideImg {
  width: 80%;
}

.slideRow {
  width: 100%;
}

.slideCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.fullscreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}




.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}



.signup {
  width: 80%;
}

.right {
  text-align: right;
}



@media (max-width: 768px) {
  .slideImg {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  

.fullscreen {
  min-height: 100vh;
  width: 100vw;
  display: block;
  flex-direction:row;
  align-items:flex-start;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 60px;
}

.hideText{
  display:none;
}


  .intro .carousel {
    width: 100%;
    height: 100%;
  }

  .right {
    text-align: center;
  }
  
  .left {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}


@media (max-height: 690px) {
  
  .fullscreen {
    
    width: 100vw;
    display: flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
  
  }

@media (max-width: 668px) {

  .intro{
    font-size: 12px;
  }

}