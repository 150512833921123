header {
    position: fixed;
    width: 100%;
    height: 50px;
    margin: 0px;
    padding: 10px;
    z-index:100;
  }
  
  header select {
    padding: 2px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    float: right;
    height: 34px;
    background-color: #353b45;
    border:none;
    color:#fefefe;
  }
    
  header .smallLogo {
    height: 40px;
    float: left;
  }
  
  header .btn-subscribe {
    float: right; /* Assuming you have a subscribe class for the subscribe element */
  }
  